import { OYEN_FAQ } from '@utils/constant';
import React from 'react';

type State = {
  opened: boolean;
  url: string;
};
export enum FAQActions {
  Open,
  Close,
  setFAQ,
}
type Action = { type: FAQActions; payload?: any };
type Dispatch = (action: Action) => void;
type ContextProps = {
  state: State;
  dispatch: Dispatch;
};
type FAQProviderProps = {
  children: React.ReactNode;
};

const FAQReducer = (state: State, action: Action) => {
  switch (action.type) {
    case FAQActions.Open:
      return { ...state, opened: true };
    case FAQActions.Close:
      return { ...state, opened: false, url: OYEN_FAQ.ROOT };
    case FAQActions.setFAQ:
      return { ...state, opened: true, url: action.payload.url };
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveDeps: never = action.type;
      throw new Error(`Invalid action: ${exhaustiveDeps}`);
  }
};

const FAQContext = React.createContext<ContextProps | undefined>(undefined);

const FAQProvider = ({ children }: FAQProviderProps) => {
  const initialState = {
    opened: false,
    url: OYEN_FAQ.ROOT,
  };

  const [state, dispatch] = React.useReducer(FAQReducer, initialState);

  const value = { state, dispatch };

  return <FAQContext.Provider value={value}>{children}</FAQContext.Provider>;
};

const useFAQ = () => {
  const context = React.useContext(FAQContext);

  if (context === undefined) {
    throw new Error('useFAQ must be used inside FAQProvider');
  }

  return context;
};

export { FAQProvider, useFAQ };
