import { ID_TYPE, PLAN_BILL_CYCLE, PLAN_TYPE } from '../utils/constant';
import {
  PetHealthStatus,
  PetPoliciesInvoice,
  PetType,
  PetWeight,
  Sex,
} from '@prisma/client';

import { PlanCardProps } from '@components/PlanCard';
import { PricingBreakdown } from '@utils/priceBreakdown';
import React from 'react';

export interface IPet {
  id: string;
  name?: string;
  petName: string;
  petType: PetType;
  petDateOfBirth: Date;
  petSex: Sex;
  petBreed: string;
  petWeight?: PetWeight;
  petPhysicallyAble: boolean;
  petWellBehaved: boolean;
  petResidence: boolean;
  petMicrochip: boolean;
  petVaccinated?: boolean;
  microchipNumber?: string;
  needMicroChip?: boolean;
  noMicroChip?: boolean;
  morePet?: boolean;
  healthStatus?: PetHealthStatus;
  healthCondition?: string[];
  congenitalDisease?: boolean;
  congenitalDiseaseDetails?: string;
  accidentDetails?: string;
  accident?: boolean;
  policy?: Policy;
  loadingPercent?: number | null;
  loadingAmount?: number | null;

  deductiblesAmount?: number | null;
  monthlyLoading?: number | null;
  ncdAmount?: number;
  ncdPercent?: number;

  isBuyback?: boolean;

  yearlyNcdPercent?: number;
  yearlyNcdAmount?: number;
  startDate?: Date;
  endDate?: Date;
  groupId?: string;
  userPetPolicyId?: string;
  vetMedicalLimit?: number | null;
  petPoliciesInvoice?: PetPoliciesInvoice[];
  billCycle: PLAN_BILL_CYCLE;
  source?: string;
  parentPolicyId?: string;

  parentPolicy?: IPet;
  pricingBreakdown?: PricingBreakdown;
  otherHealthConditions?: string;

  originalYearlyPrice?: number;
  hasApprovedClaims?: boolean;
}

export type Policy = PlanCardProps & {
  id: number;
  name: string;
  plan: string;
  petType: PetType;

  createdAt: string;
  updatedAt: string;

  freeMicroChip: boolean;

  thirdPartyLimit: number;
  vetMedicalLimit: number;
  burialLimit: number;

  monthlyPrice: number;
  yearlyPrice: number;

  maxClinicOvernightStay?: number;
  followupTreatmentDays?: number;
};

export interface IAdresss {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: number;
  country: string;
}

export interface IAddressForm {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface IOwner extends IAdresss {
  fullName: string;
  nickname: string;
  sex: string;
  dateOfBirth: Date | string;
  nationality: string;
  idType: ID_TYPE;
  idNumber: string;
  contact: string;
  email: string;
  leadId?: string;
  referralCode: string;
}

export interface IFormOwner {
  fullName?: string;
  nickname?: string;
  sex?: string;
  dateOfBirth?: Date | string;
  nationality?: string;
  idType?: ID_TYPE;
  idNumber?: string;
  contact?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  referralCode?: string;
}

const initialState = {
  purchaseType: PLAN_TYPE.PLAN,
  pets: [],
  owner: {
    country: 'MY',
    nationality: 'MY',
  } as IOwner,
  step: 0,
};
export enum ActionType {
  SET_PURCHASE_TYPE,
  SET_STEP,
  ADD_PET,
  REMOVE_PET,
  UPDATE_PET,
  RESET_PET,
  SET_POLICY,
  UPDATE_OWNER,
  LOAD_ELIGIBILITY,
}
type State = {
  pets: IPet[];
  owner: IOwner;
  purchaseType: PLAN_TYPE;
  step: number;
};
type Action = { type: ActionType; payload?: any };
type PlanContextProps = {
  state: State;
  dispatch: (action: Action) => void;
};

type PlanProviderProps = {
  children: React.ReactNode;
};

const PlanContext = React.createContext<PlanContextProps | undefined>(
  undefined
);

const savePlan = (state: State) => {
  window.localStorage.planPets = JSON.stringify(state.pets);
  window.localStorage.planOwner = JSON.stringify(state.owner);
  window.localStorage.purchaseType = JSON.stringify(state.purchaseType);
  return state;
};

const loadInitialState = (pet: any = null) => {
  const cachedState: any = {};
  if (typeof window !== 'undefined' && window.localStorage.planPets) {
    cachedState.pets = JSON.parse(window.localStorage.planPets);
    cachedState.owner = JSON.parse(window.localStorage.planOwner);
  }

  try {
    cachedState.purchaseType = JSON.parse(
      window.localStorage.purchaseType || PLAN_TYPE.PLAN
    );
  } catch (error) {
    console.log('Initializing purchase type error');
  }

  if (typeof window !== 'undefined') {
    if (
      (!cachedState.owner || Object.keys(cachedState.owner).length <= 2) &&
      window.localStorage.eligibility &&
      window.localStorage.eligibility !== 'undefined'
    ) {
      console.log('loading eligibility to plan');
      // use eligibility
      const eligibilityData = JSON.parse(window.localStorage.eligibility).data;
      if (!cachedState.owner) {
        cachedState.owner = {};
      }
      // cachedState.owner = eligibilityData.data;
      cachedState.owner.nickname = eligibilityData.name;
      cachedState.owner.contact = eligibilityData.contact;
      cachedState.owner.email = eligibilityData.email;
      if ((!cachedState.pets || cachedState.pets.length < 1) && pet) {
        cachedState.pets = [{ id: new Date().getTime(), ...pet }];
      }
    } else {
      // console.log('plan exists', cachedState);
    }
  }

  const finalInitialState = {
    ...initialState,
    owner: {
      ...initialState.owner,
      ...(cachedState.owner ? cachedState.owner : {}),
    },
    pets: [
      ...(cachedState.pets && cachedState.pets.length > 0
        ? cachedState.pets
        : initialState.pets),
    ],
  };
  // console.log('finalstate', finalInitialState, cachedState.owner, (typeof window !== 'undefined' ? window.localStorage.planOwner : {}));
  return finalInitialState;
};

const PlanReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_STEP:
      return savePlan({ ...state, step: action.payload });
    case ActionType.SET_PURCHASE_TYPE:
      return savePlan({ ...state, purchaseType: action.payload });
    case ActionType.ADD_PET:
      // console.log('add-pet', action.payload);
      return savePlan({
        ...state,
        pets: [
          ...state.pets,
          {
            ...action.payload,
            id:
              action.payload.userPetPolicyId ||
              '_' + Math.floor(Math.random() * 999999) + new Date().getTime(),
          },
        ],
      });
    case ActionType.UPDATE_PET:
      if (action.payload.billCycle === 'Monthly') {
        return savePlan({
          ...state,
          pets: state.pets.map((p: IPet) => {
            if (p.id === action.payload.id) {
              return { ...p, ...action.payload, needMicroChip: false };
            }
            return p;
          }),
        });
      }

      return savePlan({
        ...state,
        pets: state.pets.map((p: IPet) => {
          if (p.id === action.payload.id) {
            return { ...p, ...action.payload };
          }
          return p;
        }),
      });
    case ActionType.REMOVE_PET:
      return savePlan({
        ...state,
        pets: state.pets.filter((p: IPet) => p.id !== action.payload.id),
      });
    case ActionType.LOAD_ELIGIBILITY:
      return savePlan({
        ...loadInitialState(action.payload),
      });
    case ActionType.RESET_PET:
      window.localStorage.planPets = '';
      window.localStorage.planOwner = '';
      return savePlan({
        ...initialState,
        purchaseType: state.purchaseType,
      });
    case ActionType.SET_POLICY:
      return savePlan({
        ...state,
        pets: state.pets.map((p: IPet) =>
          p.id === action.payload.id
            ? { ...p, policy: action.payload.policy }
            : p
        ),
      });
    case ActionType.UPDATE_OWNER:
      // console.log('UPDATE_OWNER', { ...action.payload });
      return savePlan({
        ...state,
        owner: { ...state.owner, ...action.payload },
      });
    default:
      console.error('type not supported?', action);
      throw new Error(`Unhandled action: ${action.type}`);
  }
};

const PlanProvider = ({ children }: PlanProviderProps) => {
  const finalInitialState = loadInitialState();
  // console.log('plan finalInitialState', finalInitialState, cachedState);
  const [state, dispatch] = React.useReducer(PlanReducer, finalInitialState);
  // console.log('plan finalInitialState', { ...state.owner }, { ...finalInitialState.owner });
  return (
    <PlanContext.Provider value={{ state, dispatch }}>
      {children}
    </PlanContext.Provider>
  );
};

const usePlan = () => {
  const context = React.useContext(PlanContext);
  if (context === undefined) {
    throw new Error('usePlan must be used inside PlanProvider');
  }
  return context;
};

export { PlanProvider, usePlan };
