import { create } from 'zustand';
import { createSelectors } from './create-selectors';
import { createJSONStorage, persist } from 'zustand/middleware';

import { OwnerSlice, createOwnerSlice } from '@slices/owner';
import { AddressSlice, createAddressSlice } from '@slices/address';
import { PetSlice, createPetSlice } from '@slices/pet';
import { PricingSlice, createPricingSlice } from '@slices/pricing';
import { CoverageSlice, createCoverageSlice } from '@slices/coverage';
import { UtilSlice, createUtilSlice } from '@slices/util';
import { PlanSlice, createPlanSlice } from '@slices/plan';
import {
  UserPetPolicySlice,
  createUserPetPolicySlice,
} from '@slices/user-pet-policy';

export interface Store
  extends PetSlice,
    PlanSlice,
    OwnerSlice,
    PricingSlice,
    AddressSlice,
    CoverageSlice,
    UserPetPolicySlice,
    UtilSlice {}

const useStoreBase = create<Store>()(
  persist(
    (...set) => ({
      ...createPetSlice(...set),
      ...createPlanSlice(...set),
      ...createOwnerSlice(...set),
      ...createPricingSlice(...set),
      ...createCoverageSlice(...set),
      ...createAddressSlice(...set),
      ...createUtilSlice(...set),
      ...createUserPetPolicySlice(...set),
    }),
    {
      name: 'eligibility-revamp', // unique name of the store
      storage: createJSONStorage(() => localStorage), // or sessionStorage
    }
  )
);

export const useStore = createSelectors(useStoreBase);
