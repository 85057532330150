/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import 'antd/dist/antd.css';
import '../styles/globals.css';
import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../next-i18next.config.js';
import { IconLibrary } from '@components/Icon';
import Layout from '@components/Layout';
import { AuthProvider } from '@lib/auth';
import { FAQProvider } from '@contexts/faq';
import DashboardLayout from '@components/Dashboard/Layout';
import { UserInfoProvider } from '@contexts/userInfo';
import AuthLayout from '@components/Auth/Layout';
import { PlanProvider } from '@contexts/plan';
import ErrorBoundary from '../components/ErrorBoundary';
import { TRPCContextProvider } from '../lib/trpc.provider';
// import dynamic from 'next/dynamic';
// const ClientOnlyTRPCContextProvider = dynamic(() => Promise.resolve(TRPCContextProvider), {
//   ssr: false
// })

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { pathname } = router;

  const renderLayout = () => {
    return pathname.includes('dashboard') ? (
      pathname.includes('auth') ? (
        <AuthLayout>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </AuthLayout>
      ) : (
        <DashboardLayout>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </DashboardLayout>
      )
    ) : (
      <Layout>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Layout>
    );
  };

  return (
    <>
      <Head>
        <title>Oyen - Malaysia&#39;s Best Pet Takaful & Insurance</title>
        <meta
          name="description"
          content="Oyen - Malaysia&#39;s Best Pet Takaful & Insurance"
        />
        <link rel="icon" href="/fav-32.png" />
      </Head>
      <IconLibrary />
      <PlanProvider>
        <UserInfoProvider>
          <FAQProvider>
            <AuthProvider>
              <TRPCContextProvider>{renderLayout()}</TRPCContextProvider>
            </AuthProvider>
          </FAQProvider>
        </UserInfoProvider>
      </PlanProvider>
    </>
  );
}

const i18NApp =
  false && process.env.NODE_ENV !== 'production'
    ? /* eslint-disable */
      // @ts-ignore
      appWithTranslation(MyApp, nextI18NextConfig)
    : appWithTranslation(MyApp);

export default i18NApp;
