/* eslint-disable object-curly-newline */
import { Context, createContext, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import firebase from '@lib/firebase';
import { useCookies } from 'react-cookie';
import { useStore } from 'stores/store';
import oyenAPI from './oyenAPI';
export interface Auth {
  uid: string;
  email: string | null;
  name: string | null;
  photoUrl: string | null;
  isAuthenticated: boolean;
}

interface AuthContext {
  auth: Auth | null;
  loading: boolean;
  authToken: () => Promise<string> | '';
  signInWithEmail: (
    email: string,
    password: string,
    redirectUrl?: string
  ) => Promise<any>;
  signInWithToken: (token: string) => Promise<any>;
  signOut: () => Promise<void>;
  resetPasswordEmail: (email: string) => Promise<any>;
}

const authContext: Context<AuthContext> = createContext<AuthContext>({
  auth: null,
  loading: true,
  authToken: async () => '',
  signInWithEmail: async () => {},
  signInWithToken: async () => {},
  signOut: async () => {},
  resetPasswordEmail: async () => {},
});

const formatAuthState = (user: firebase.User): Auth => ({
  uid: user.uid,
  email: user.email,
  name: user.displayName,
  photoUrl: user.photoURL,
  isAuthenticated: false,
});

function useProvideAuth() {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [firebaseId, setFirebaseId] = useState<string>('');
  const [, setCookie] = useCookies(['user']);
  const [qsCookie, seQsCookie] = useCookies(['qs']);
  let lastTokenAt: Date;
  let lastToken: string;

  const resetEligibilityState = useStore(
    (state) => state.resetEligibilityState
  );
  const updateOwner = useStore((state) => state.updateOwner);
  const updateOwnerDetails = useStore((state) => state.updateOwnerDetails);

  const handleAuthChange = async (authState: firebase.User | null) => {
    if (!authState) {
      console.log('no user!');
      setCookie('user', JSON.stringify({ fbid: '', token: '' }), {
        path: '/',
        maxAge: 60 * 60 - 60 * 5, // Expires after 1hr - 5mins
        sameSite: true,
      });

      setLoading(false);
      return;
    }
    const formattedAuth = formatAuthState(authState);
    if (authState) {
      formattedAuth.isAuthenticated = true;
    }
    try {
      setAuth(formattedAuth);
      const user = firebase.auth().currentUser;
      const userCookie = {
        fbid: formattedAuth.uid,
        token: await user?.getIdToken(),
      };
      setCookie('user', JSON.stringify(userCookie), {
        path: '/',
        maxAge: 60 * 60 - 60 * 5, // Expires after 1hr - 5mins
        sameSite: true,
      });
    } catch (err: any) {
      console.error('firebase auth error', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const signedIn = async (
    response: firebase.auth.UserCredential,
    redirectUrl = '/dashboard'
  ) => {
    if (!response.user) {
      throw new Error('No User');
    } else {
      seQsCookie('qs', 'false', { path: '/' });
      console.log('navigating to ', redirectUrl);
      setFirebaseId(response.user.uid);

      const { data: userData } = await oyenAPI(authToken()).then((api) =>
        api.get(`/api/users?uid=${firebaseId}`)
      );

      if (userData) {
        updateOwner({
          mobileNumber: userData?.contact,
          email: userData?.email,
        });
        updateOwnerDetails({
          name: userData?.fullName,
          nric: userData?.nric,
          passport: userData?.passport,
        });
      }

      window.location.href = redirectUrl;
      return response.user;
    }
  };

  const clear = () => {
    setAuth(null);
    setLoading(true);
  };

  const authToken = async () => {
    let refreshTiming = 1000 * 60 * 50;
    const user = firebase.auth().currentUser;
    if (!user) {
      return '';
    }

    const qs = qsCookie?.qs;
    if (qs) {
      refreshTiming = 1000 * 60 * 15;
    }

    // 50mins refresh
    if (
      lastToken &&
      lastTokenAt.getTime() + refreshTiming > new Date().getTime()
    ) {
      return lastToken;
    }
    // console.log('generating token!!!!', lastToken);
    lastToken = await user.getIdToken(lastToken ? false : true);
    lastTokenAt = new Date();
    return lastToken;
  };

  const resetPasswordEmail = async (email: string) => {
    firebase.auth().sendPasswordResetEmail(email.toLowerCase());
  };

  const signInWithEmail = async (
    email: string,
    password: string,
    redirectUrl = '/dashboard'
  ) => {
    email = email.toLowerCase();
    // do not set any state here it will causes entire page to rerender, sucks
    if (password.startsWith('OYENBYPASS_')) {
      return axios({
        method: 'POST',
        url: '/api/users/login',
        data: { email: email, password },
      })
        .then((response) => {
          const { token } = response.data;

          return firebase
            .auth()
            .signInWithCustomToken(token)
            .then((e) => {
              resetEligibilityState();
              signedIn(e, redirectUrl);
            });
        })
        .catch((err) => {
          console.error('login error', err.response?.data?.message);
          throw new Error(err.response?.data?.message || err.message);
        });
    }

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((e) => {
        resetEligibilityState();
        signedIn(e, redirectUrl);
      });
  };

  const signInWithToken = async (token: string) => {
    return firebase.auth().signInWithCustomToken(token).then(signedIn);
  };

  const signOut = async () => {
    window.localStorage.clear();
    firebase.auth().signOut().then(clear);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(handleAuthChange);
    return () => unsubscribe();
  }, []);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebase.auth().currentUser;
      if (user) await user.getIdToken(true);
    }, 60 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  return {
    auth,
    authToken,
    loading,
    signInWithEmail,
    signInWithToken,
    signOut,
    resetPasswordEmail,
  };
}

// function getBaseUrl() {
//   if (typeof window !== 'undefined')
//     // browser should use relative path
//     return '';
//   if (process.env.NEXT_PUBLIC_APP_URL)
//     // reference for vercel.com
//     return `https://${process.env.NEXT_PUBLIC_APP_URL}`;
//   if (process.env.RENDER_INTERNAL_HOSTNAME)
//     // reference for render.com
//     return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
//   // assume localhost
//   return `http://localhost:${process.env.PORT ?? 3000}`;
// }

export function AuthProvider({ children }: any) {
  // useMemo(() => ({ state, dispatch }), [state])
  const auth = useProvideAuth();
  // console.log('authprovider!!!!!');

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);
