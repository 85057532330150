import React, { useEffect } from 'react';
import { ButtonIcon } from '@components/Button';
import { useTranslation } from 'next-i18next';
import { cn } from '@lib/utils';

type ModalProps = {
  opened: boolean;
  onClose: () => void | undefined | null;
  children: React.ReactNode;
  large?: boolean;
  extraLarge?: boolean;
  action?: React.ReactNode;
  title?: string;
  subtitle?: string;
  subTitleClass?: string;
  header?: boolean;
  logo?: React.ReactNode | React.ReactNode[];
  shadow?: boolean;
  flexGrow?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  children,
  action,
  onClose,
  opened,
  large,
  extraLarge,
  title,
  subtitle,
  subTitleClass,
  header = true,
  shadow = true,
  flexGrow = true,
  logo,
}: ModalProps) => {
  const { t } = useTranslation('planCard');

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [opened]);

  return opened ? (
    <div className="fixed z-20 inset-0 flex flex-col bg-black bg-opacity-50 align-middle justify-center">
      <section
        className={cn(
          'relative w-full mx-auto bg-white flex flex-col overflow-auto md:flex-grow-0 md:rounded md:my-10',
          flexGrow && 'flex-grow',
          large
            ? 'max-w-3xl'
            : subtitle === t('editPet_plan_title')
            ? 'lg:max-w-6xl sm:max-w-lg'
            : 'md:max-w-lg',
          extraLarge && 'sm:max-w-8xl md:max-w-8xl xl:max-w-6xl'
        )}
      >
        {header && (
          <header className={cn('flex gap-4 p-2 pl-4', shadow && 'shadow-md')}>
            {logo}
            {(title || subtitle) && (
              <div>
                {title && <h3 className="font-bold text-lg">{title}</h3>}
                {subtitle && <p className={subTitleClass}>{subtitle}</p>}
              </div>
            )}
            <ButtonIcon
              text="Close"
              icon="close"
              onClick={onClose}
              className="absolute top-1 right-1"
            />
          </header>
        )}
        {!header && (
          <ButtonIcon
            text="Close"
            icon="close"
            onClick={onClose}
            className="absolute top-1 right-1"
          />
        )}
        <div className="overflow-auto">{children}</div>
        {action && (
          <footer className="bottom-0 px-4 border-t py-3 flex gap-2">
            {action}
          </footer>
        )}
      </section>
    </div>
  ) : null;
};

export default Modal;
