import * as React from "react"
const PetsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#494C55"
      fillRule="evenodd"
      d="M5.59 14H3.333a8.333 8.333 0 1 0 0-10H5.59a6.667 6.667 0 1 1 0 10Zm5.243-5.833H4.167v-2.5L0 9l4.167 3.334v-2.5h6.666V8.167Z"
      clipRule="evenodd"
    />
  </svg>
)
export default PetsIcon
