import { CheckCircleFilled } from '@ant-design/icons';
import Globe from '@assets/images/globe.svg';
import { ENABLE_TAKAFUL, LANGUAGES } from '@utils/constant';
import { Button, Dropdown, Menu, Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const { Text } = Typography;

interface iSelectLanguageProps {
  languages: typeof LANGUAGES;
}

const checkLocale = (
  locale: string | undefined,
  languages: typeof LANGUAGES
) => {
  switch (locale) {
    case 'en':
      return languages.en.label;
    case 'my':
      return languages.ms.label;
    // case 'zh':
    //   return languages.zh_hans.label;
    case 'eni':
      return languages.en_i.label;
    case 'myi':
      return languages.ms_i.label;
    // case 'zhi':
    //   return languages.zh_i.label;
    default:
      return locale;
  }
};

const SelectLanguage: React.FC<iSelectLanguageProps> = ({ languages }) => {
  const showTakaful = ENABLE_TAKAFUL;

  const router = useRouter();
  const [locale, setLocale] = useState<{
    label: string | undefined;
    value: string | undefined;
  }>({
    label: languages.en.label,
    value: languages.en.value,
  });
  const [isTakaful, setIsTakaful] = useState<boolean>(false);

  // TODO: rethink how to implement this. Cause to re-render page more than once
  // has to implement a useEffect due to the refresh base on how nextjs does localization routing
  useEffect(() => {
    setLocale({
      label: checkLocale(router.locale, languages),
      value: router.locale,
    });

    setIsTakaful(
      router.locale === 'myi' ||
        router.locale === 'eni' ||
        router.asPath.includes('takaful') ||
        router.asPath.includes('dashboard/pets')
    );
  }, [router.locale]);

  // TODO: need to refactor this, too much repeating codes
  const menuItems = [
    {
      key: languages.en.value,
      onClick: () => {
        // replace the current url with the new locale
        window.location.href = `/${languages.en.value}${router.asPath}`;
      },
      label: (
        <Link href={router.pathname} locale={languages.en.value}>
          <div className="flex items-center space-x-1">
            {locale.value === languages.en.value && (
              <Image src={Globe} width={15} height={15} alt="globe" />
            )}
            <Text>{languages.en.label}</Text>
            {locale.value === languages.en.value && (
              <CheckCircleFilled style={{ color: '#48BB78' }} />
            )}
          </div>
        </Link>
      ),
    },
    {
      key: languages.ms_i.value,
      onClick: () => {
        window.location.href = `/${languages.ms_i.value}${router.asPath}`;
      },
      label: (
        <Link href={router.pathname} locale={languages.ms_i.value}>
          <div className="flex items-center space-x-1">
            {locale.value === languages.ms_i.value && (
              <Image src={Globe} width={15} height={15} alt="globe" />
            )}
            <Text>{languages.ms_i.label}</Text>
            {locale.value === languages.ms_i.value && (
              <CheckCircleFilled style={{ color: '#48BB78' }} />
            )}
          </div>
        </Link>
      ),
    },
    // {
    //   key: languages.zh_hans.value,
    //   onClick: (e: any) => {
    //     setLocale({ value: e.key, label: languages.zh_hans.label });
    //   },
    //   label: (
    //     <Link href={router.pathname} locale={languages.zh_hans.value}>
    //       <div className="flex items-center space-x-1">
    //         {locale.value === languages.zh_hans.value && (
    //           <Image src={Globe} width={15} height={15} alt="globe" />
    //         )}
    //           <Text>{languages.zh_hans.label}</Text>
    //         {locale.value === languages.zh_hans.value && (
    //           <CheckCircleFilled style={{ color: '#48BB78' }} />
    //         )}
    //       </div>
    //     </Link>
    //   ),
    // },
    {
      key: languages.en_i.value,
      onClick: () => {
        window.location.href = `/${languages.en_i.value}${router.asPath}`;
      },
      label: (
        <Link href={router.pathname} locale={languages.en_i.value}>
          <div className="flex items-center space-x-1">
            {locale.value === languages.en_i.value && (
              <Image src={Globe} width={15} height={15} alt="globe" />
            )}
            <Text>EN</Text>
            {locale.value === languages.en.value && (
              <CheckCircleFilled style={{ color: '#48BB78' }} />
            )}
          </div>
        </Link>
      ),
    },
    {
      key: languages.ms_i.value,
      onClick: () => {
        window.location.href = `/${languages.ms_i.value}${router.asPath}`;
      },
      label: (
        <Link href={router.pathname} locale={languages.ms_i.value}>
          <div className="flex items-center space-x-1">
            {locale.value === languages.ms.value && (
              <Image src={Globe} width={15} height={15} alt="globe" />
            )}
            <Text>{languages.ms_i.label}</Text>
            {locale.value === languages.ms.value && (
              <CheckCircleFilled style={{ color: '#48BB78' }} />
            )}
          </div>
        </Link>
      ),
    },
  ];

  const items = (
    <Menu
      items={
        isTakaful && showTakaful ? menuItems.slice(2) : menuItems.slice(0, 2)
      }
    />
  );

  return (
    <div className="bg-transparent border px-1.5 py-1.5 rounded-full cursor-pointer items-center">
      <Dropdown
        className="justify-evenly p-1"
        overlay={items}
        trigger={['click']}
        overlayStyle={{ borderRadius: 10 }}
      >
        <Button type="text" className="flex items-center space-x-1 bg-white">
          <Image src={Globe} width={15} height={15} alt="globe" />
          {checkLocale(router.locale, languages)}
        </Button>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
