import { Store } from '@stores/store';
import { StateCreator } from 'zustand';

interface UtilSlice {
  resetEligibilityState: () => void;
  resetPetState: () => void;
  leadId: string | null;
  setLeadId: (leadId: string) => void;
}

const createUtilSlice: StateCreator<Store, [], [], UtilSlice> = (set) => {
  return {
    resetEligibilityState: () =>
      set((state) => ({
        ...state,
        pet: {},
        plan: {},
        coverage: null,
        pricing: null,
        owner: {
          mobileNumber: '+60',
        },
        address: {},
      })),
    resetPetState: () =>
      set((state) => ({
        ...state,
        pet: {},
        plan: {},
        coverage: null,
        pricing: null,
      })),
    leadId: null,
    setLeadId: (leadId: string) => set({ leadId }),
  };
};

export { type UtilSlice, createUtilSlice };
