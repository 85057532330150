import { PaymentStatus, PolicyStatus } from '@prisma/client';

type Policy = {
  id: number;
  status: PolicyStatus;
  paymentStatus: PaymentStatus;
  groupId?: number;
};

// filter non renewal
//  or pending payment
export const filterVisiblePets = (p: Policy) => {
  const excludedStatus = [
    PolicyStatus.INACTIVE,
    PolicyStatus.EXPIRED,
    PolicyStatus.APPROVED,
    PolicyStatus.PENDING_MODIFY,
    PolicyStatus.PENDING_REVIEW,
    PolicyStatus.REJECTED,
    PolicyStatus.OYEN_REVIEWED,
    PolicyStatus.CANCELLED,
    PolicyStatus.OYEN_APPROVED,
  ];
  // Filter out policy status null and payment status null
  if (!p.status && !p.paymentStatus) {
    return false;
  }

  if (!p.status && p.paymentStatus === PaymentStatus.OVERDUE) {
    return true;
  }

  if (!p.status && p.paymentStatus === PaymentStatus.PROCESSING) {
    return true;
  }

  if (p.groupId && !p.paymentStatus && p.status !== PolicyStatus.APPROVED) {
    return true;
  }

  if (
    !!p.status &&
    excludedStatus.filter((x) => x === p.status)?.length === 0
  ) {
    return true;
  }

  return false;
};
