import React from 'react';
import { CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { cn } from '@lib/utils';

interface iHeadersProps {
  title: React.ReactNode;
  bgColor?: string;
  iconColor?: string;
  isCheckIcon?: boolean;
  roundedEdge?: string;
  className?: string;
  icon?: React.ReactNode;
}

const Header: React.FC<iHeadersProps> = ({
  title,
  bgColor = 'bg-gray-200',
  iconColor,
  isCheckIcon = false,
  roundedEdge = 'rounded-xl',
  className,
  icon,
}) => {
  return (
    <div className={className}>
      <div
        className={`flex flex-row items-center space-x-2 ${bgColor} py-[0.375rem] px-3 ${roundedEdge} break-normal`}
      >
        {icon ? (
          icon
        ) : isCheckIcon ? (
          <CheckOutlined
            className={cn(iconColor ? iconColor : 'text-lightGreen')}
          />
        ) : (
          <ExclamationCircleFilled
            className={cn(iconColor ? iconColor : 'text-gray-500')}
          />
        )}
        <div className="w-full">{title}</div>
      </div>
    </div>
  );
};

export default Header;
