/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const ChainedBackend = require('i18next-chained-backend');
// const FSBackend = require('i18next-fs-backend/cjs');
// const LocizeBackend = require('i18next-locize-backend/cjs');
const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'my', 'zh', 'eni', 'myi', 'zhi'],
    reloadOnPrerender: true,
  },
  ...(typeof window === 'undefined'
    ? { localePath: path.resolve('./public/locales') }
    : {}),
  serializeConfig: true,
};
