import { Drawer, Layout, Menu } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import Icon from '@components/Icon';
import { ActionType, usePlan } from '@contexts/plan';
import { useUserInfo } from '@contexts/userInfo';
import { useAuth } from '@lib/auth';
import oyenAPI from '@lib/oyenAPI';
import {
  ENABLE_APM,
  LANGUAGES,
  OYEN_FAQ,
  OYEN_LANDING_PAGE,
  PRODUCT_SLUG,
} from '@utils/constant';
import { filterVisiblePets } from '@utils/dashboard';
import { gtm } from '@utils/gtm';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Loader from '../Loader';
import Logo from './Logo';

import HamburgerIcon from '@assets/images/icons/hamburger.svg';
import BenefitsIcon from '@assets/tsxs/BenefitsIcon';
import ClaimsHistoryIcon from '@assets/tsxs/ClaimsHistoryIcon';
import ListIcon from '@assets/tsxs/ListIcon';
import PetsIcon from '@assets/tsxs/PetsIcon';
import ReferralIcon from '@assets/tsxs/ReferralIcon';
import ProductChooser from '@components/ProductChooser';
import SelectLanguage from '@components/SelectLanguage';
import LogoImage from '@public/logo.svg';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useStore } from '@stores/store';
import { PolicyStatus } from '@prisma/client';

const Sidebar: React.FC = () => {
  const router = useRouter();
  const { auth, signOut, authToken } = useAuth();
  const { state, dispatch } = useUserInfo();
  const [numOfPolicies, setPoliciesNum] = useState<number>();
  const [mobileDrawer, showMobileDrawer] = useState(false);
  const { dispatch: planDispatch } = usePlan();
  const { t: td } = useTranslation('dashboard');
  const { t: tc } = useTranslation('common');
  const [isTakafulUser, setIsTakafulUser] = useState(false);

  const [opened, setOpened] = useState(false);

  const setLocalUpp = useStore((s) => s.setUserPetPolicies);

  const toggleMobileDrawer = () => {
    showMobileDrawer(!mobileDrawer);
  };
  const fetchUserPolicies = useCallback(async () => {
    dispatch({ type: 'LOADING_POLICIES', payload: true });
    if (auth?.isAuthenticated) {
      await oyenAPI(authToken()).then((api) =>
        api.get('/api/user-pet-policies').then(({ data }) => {
          dispatch({
            type: 'SET_USER_PET_POLICIES',
            payload: data.userPetPolicies,
          });

          setLocalUpp(data.userPetPolicies as any);

          setIsTakafulUser(
            data.userPetPolicies.some(
              (policy: any) => policy.product === PRODUCT_SLUG.ZURICH_TAKAFUL
            )
          );

          setPoliciesNum(data.userPetPolicies.filter(filterVisiblePets).length);
        })
      );
      dispatch({ type: 'LOADING_POLICIES', payload: false });
    }
  }, [auth]);

  useEffect(() => {
    fetchUserPolicies();
  }, [auth]);

  if (!auth) return null;

  const menuItems: ItemType[] = [
    {
      key: '/dashboard',
      label: <Link href="/dashboard">{tc('sb_dashboard')}</Link>,
      icon: <Icon name="home" size={1.3} />,
    },
    {
      key: '/dashboard/todo',
      label: <Link href="/dashboard/todo">{tc('sb_onboarding_list')}</Link>,
      icon: <ListIcon />,
    },
    {
      key: '/dashboard/referral',
      label: (
        <Link href="/dashboard/referral">{tc('sb_referral_history')}</Link>
      ),
      icon: <ReferralIcon />,
    },
    {
      key: '/dashboard/benefits',
      label: (
        <Link href="https://www.oyen.my/oyen-partner-benefits">
          <a target="_blank" rel="noreferrer">
            {tc('sb_oyen_partner_benefits')}
          </a>
        </Link>
      ),
      icon: <BenefitsIcon />,
    },
  ];

  if (auth && state.userPetPolicies) {
    const activePolicies = state.userPetPolicies.filter(
      (p) => p.status === PolicyStatus.ACTIVE
    );
    const insuredPets: ItemType = {
      key: 'insuredPets',
      label: (
        <p className="group-header">
          {td('insured_pets')}({numOfPolicies})
        </p>
      ),
      children: [],
    };

    activePolicies.forEach((pol) =>
      insuredPets.children!.push({
        key: pol.status
          ? `/dashboard/pets/${pol.id}`
          : `/dashboard/billing/${
              pol.groupId ? `OYENGROUP${pol.groupId}` : `OYEN${pol.id}`
            }`,
        icon: <PetsIcon />,
        onClick: () =>
          gtm.track('sidebar_insured_pet_clicked', {
            email: state.user.email,
            user_id: state.user.id,
            pet_policy_id: pol.id,
            pet_id: pol.pets.id,
          }),
        label: (
          <Link
            href={
              pol.status
                ? `/dashboard/pets/${pol.id}`
                : `/dashboard/billing/${
                    pol.groupId ? `OYENGROUP${pol.groupId}` : `OYEN${pol.id}`
                  }`
            }
          >
            {pol.pets.name}
          </Link>
        ),
      })
    );

    menuItems.push(insuredPets);
  }

  menuItems.push(
    ...[
      {
        key: '/plan',
        onClick: () => {
          gtm.track('sidebar_add_new_pet', {
            email: state.user.email,
            user_id: state.user.id,
          });

          planDispatch({ type: ActionType.RESET_PET, payload: null });

          setOpened(true);
        },
        label: (
          <div className="w-full rounded-lg">
            <div className="h-[120px] !p-0 flex items-center justify-center">
              <img
                src={`/api/helper/image?key=static/sidebar/add-new-pet-button-tng-cashback.png`}
                alt="add new pets"
              />
            </div>
          </div>
        ),
        style: {
          height: '120px',
          padding: '0px 0px !important',
        },
      },
      {
        key: '/dashboard/claims',
        icon: <ClaimsHistoryIcon />,
        onClick: () => {
          gtm.track('sidebar_claims_history', {
            email: state.user.email,
            user_id: state.user.id,
          });
          gtm.track('Claims History Page Viewed', {
            user_id: state.user.id,
            login_status: !!auth?.isAuthenticated,
          });
          router.push('/dashboard/claims');
        },
        label: <span>{tc('sb_claims_history')}</span>,
      },
      {
        key: 'account-settings',
        label: <p className="group-header">{tc('sb_account_settings')}</p>,
        children: [
          {
            key: '/dashboard/owner-details',
            icon: <Icon name="user" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_owner_details_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <Link href="/dashboard/owner-details">
                {tc('sb_owner_details')}
              </Link>
            ),
          },
          {
            key: '/dashboard/bank-details',
            icon: <Icon name="bank" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_bank_details_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <Link href="/dashboard/bank-details">
                {tc('sb_bank_details')}
              </Link>
            ),
          },
          {
            key: '/dashboard/payment-method',
            icon: <Icon name="payment" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_payment_method_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <Link href="/dashboard/payment-method">
                {tc('sb_payment_method')}
              </Link>
            ),
          },
          {
            key: '/dashboard/billing',
            icon: <ListIcon />,
            onClick: () =>
              gtm.track('sidebar_billing_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: <Link href="/dashboard/billing">{tc('sb_billing')}</Link>,
          },
          {
            key: '/dashboard/update-password',
            icon: <Icon name="key" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_update_pwd_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <Link href="/dashboard/update-password">
                {tc('sb_update_password')}
              </Link>
            ),
          },
        ],
      },
      {
        key: 'support',
        label: <p className="group-header">{tc('sb_support')}</p>,
        children: [
          {
            key: '/dashboard/faq',
            icon: <Icon name="question-mark" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_faq_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <a href={OYEN_FAQ.ROOT} target="_blank" rel="noopener noreferrer">
                {tc('sb_faq')}
              </a>
            ),
          },
          {
            key: '/dashboard/whatsapp',
            icon: <Icon name="phone" size={1.3} />,
            onClick: () =>
              gtm.track('sidebar_whatsapp_contact_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              }),
            label: (
              <Link
                href="https://api.whatsapp.com/send/?phone=60162996840&text=Hello+Oyen+Team&type=phone_number&app_absent=0"
                passHref
              >
                {tc('sb_speak_to_us')}
              </Link>
            ),
          },
          {
            key: OYEN_LANDING_PAGE,
            icon: <Icon name="logout" size={1.3} />,
            onClick: () => {
              gtm.track('sidebar_logout_clicked', {
                email: state.user.email,
                user_id: state.user.id,
              });
              signOut();
              router.push('/dashboard/auth/sign_in');
            },
            label: <span>{tc('sb_logout')}</span>,
          },
        ],
      },
    ]
  );

  const excludedKeys = ENABLE_APM ? [] : ['/dashboard/payment-method'];
  const accountSettingsKeyIndex = menuItems.findIndex(
    (item: any) => item.key === 'account-settings'
  );

  if (accountSettingsKeyIndex > -1) {
    const accountSettings: ItemType = menuItems[accountSettingsKeyIndex];

    if (
      !(
        !accountSettings ||
        !('children' in accountSettings) ||
        !accountSettings.children
      )
    )
      accountSettings.children = accountSettings.children.filter(
        (item: any) => !excludedKeys.includes(item.key)
      );

    menuItems[accountSettingsKeyIndex] = accountSettings;
  }

  return (
    <>
      {/* top right burger for mobile */}
      <ProductChooser
        typeOfPurchase={'add-pet'}
        opened={opened}
        setOpened={setOpened}
      />
      <div className="sticky flex md:hidden bg-white z-10 top-0 h-[52px] py-[14px] px-4 shadow-[0px_2px_6px_rgba(0,0,0,0.15)] w-full gap-[10px] items-center">
        <button
          onClick={() => toggleMobileDrawer()}
          className="w-6 h-6 flex items-center justify-center"
        >
          <Image src={HamburgerIcon} height={18} width={18} alt="menu-icon" />
        </button>
        <div className="flex flex-row justify-between pr-4 w-full">
          <Image src={LogoImage} height={24} width={80} alt="oyen-logo" />
          {isTakafulUser && <SelectLanguage languages={LANGUAGES} />}
        </div>
      </div>
      {/* desktop sidebar */}
      <Layout.Sider
        theme="light"
        className="dashboard-sidebar hidden md:block"
        breakpoint="md"
        collapsible={false}
        collapsedWidth="0"
      >
        <Loader loading={state.loadingPolicies}>
          <div className="logo-container">
            <div className="flex flex-row justify-between pr-4">
              <Logo />
              {isTakafulUser && <SelectLanguage languages={LANGUAGES} />}
            </div>
          </div>

          <Menu
            mode="inline"
            defaultSelectedKeys={['/dashboard']}
            selectedKeys={[router.asPath]}
            items={menuItems}
            defaultOpenKeys={['insuredPets', 'account-settings', 'support']}
            id="sidebar-menu"
          ></Menu>
        </Loader>
      </Layout.Sider>

      {/* mobile menu */}
      <Drawer
        placement="left"
        width="250px"
        className="sideMenuDrawer"
        closable={false}
        onClose={() => showMobileDrawer(false)}
        visible={mobileDrawer}
      >
        <div className="logo-container">
          <Logo />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['/dashboard']}
          selectedKeys={[router.asPath]}
          items={menuItems}
          defaultOpenKeys={['insuredPets', 'account-settings', 'support']}
        ></Menu>
      </Drawer>
      <ProductChooser
        typeOfPurchase={'add-pet'}
        opened={opened}
        setOpened={setOpened}
      />
    </>
  );
};

export default Sidebar;
