/* eslint-disable react/jsx-props-no-spreading */
import Icon, { Color } from '@components/Icon';
import { cn } from '@lib/utils';
import React from 'react';

type variantENUM = 'outlined' | 'filled' | 'normal' | 'iconbutton';
type ButtonProps = {
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  outlined?: boolean;
  filled?: boolean;
  className?: string;
  submit?: boolean;
  asText?: boolean;
  purpose?: 'button' | 'icon' | 'text';
  variant?: variantENUM;
  text?: string;
  title?: string;
  leftIcon?: string;
  rightIcon?: string;
  iconViewBox?: string;
  iconColor?: Color;
  iconSize?: number;
};

export const BASE_BUTTON_CLASS =
  'text-sm font-semibold whitespace-nowrap py-2  px-4 rounded-full border-2 inline-flex items-center justify-center gap-2';
export const GEN_BUTTON_CLASS =
  'border-transparent bg-primary text-white hover:bg-primary-400 hover:text-white focus:bg-primary-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:border-gray-100 disabled:cursor-not-allowed';
export const OUTLINED_BUTTON_CLASS =
  ' bg-white hover:bg-gray-100 !hover:border-gray-300 hover:text-black focus:border-gray-300 focus:bg-gray-200 disabled:bg-gray-100  disabled:text-gray-400  disabled:hover:border-gray-100  disabled:border-gray-100 disabled:cursor-not-allowed';
export const FILLED_BUTTON_CLASS =
  'bg-white hover:text-black hover:border-secondary focus:border-secondary focus:bg-secondary focus:text-white disabled:bg-secondary  disabled:text-white  disabled:hover:border-gray-100  disabled:border-gray-100 disabled:cursor-not-allowed disabled:color-gray-400 disabled:bg-none';
export const ICON_BUTTON_CLASS =
  'p-1 text-center rounded-full disabled:bg-gray-100  disabled:text-gray-400  disabled:hover:border-gray-100  disabled:border-gray-100 disabled:cursor-not-allowed';
export const ICON_TEXT_CLASS = 'sr-only';

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  outlined,
  filled,
  className: overrides,
  submit,
  variant,
  onClick,
  asText,
  purpose,
  title,
  text = '',
  leftIcon,
  iconViewBox,
  iconColor,
  iconSize: size,
}: ButtonProps) => {
  const variantResolved: variantENUM =
    variant ||
    (outlined && 'outlined') ||
    (filled && 'filled') ||
    (leftIcon && 'iconbutton') ||
    'normal';

  const classNamesVariantPairs = {
    outlined: [BASE_BUTTON_CLASS, OUTLINED_BUTTON_CLASS],
    filled: [BASE_BUTTON_CLASS, FILLED_BUTTON_CLASS],
    normal: [BASE_BUTTON_CLASS, GEN_BUTTON_CLASS],
    iconbutton: [BASE_BUTTON_CLASS, ICON_BUTTON_CLASS],
  } as { [key in variantENUM]: string[] };

  const classNamesResolver = (resolvedVariant: variantENUM) => {
    const extraClasses: string[] = [];

    // handle astext and purpose (This only happens on variant === 'normal')
    if (resolvedVariant === 'normal') {
      if (asText || purpose === 'text') {
        return [];
      } else {
        extraClasses.push(purpose ? purpose : '');
      }
    }

    if (resolvedVariant && classNamesVariantPairs?.[resolvedVariant]) {
      return classNamesVariantPairs?.[resolvedVariant]
        ? [...classNamesVariantPairs[resolvedVariant], ...extraClasses]
        : [];
    } else {
      return classNamesVariantPairs?.['normal']
        ? [...classNamesVariantPairs['normal'], ...extraClasses]
        : [];
    }
  };

  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      title={title || text || ''}
      className={cn(classNamesResolver(variantResolved), overrides)}
    >
      {leftIcon && (
        <Icon
          name={leftIcon}
          color={iconColor}
          size={size || 1.5}
          {...(iconViewBox ? { viewBox: iconViewBox } : {})}
        />
      )}
      {children}
      {purpose === 'icon' ||
        (leftIcon && <span className={ICON_TEXT_CLASS}>{text}</span>)}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  outlined: false,
  submit: false,
  className: '',
};
export default React.memo(Button);

type ButtonIconProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon: string;
  text: string;
  size?: number;
  title?: string;
  disabled?: boolean;
  className?: string;
  iconViewBox?: string;
  iconColor?: Color;
};

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  text,
  title,
  size,
  disabled = false,
  onClick,
  className,
  iconViewBox,
  iconColor,
}: ButtonIconProps) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`button-icon ${className}`}
    title={title || text}
  >
    <Icon
      name={icon}
      color={iconColor}
      size={size || 1.5}
      {...(iconViewBox ? { viewBox: iconViewBox } : {})}
    />
    <span className="sr-only">{text}</span>
  </button>
);
