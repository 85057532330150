// dayjs.ts
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isTomorrow from 'dayjs/plugin/isToday';
import isToday from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

const getStartOfDayMYT = (date: string | Date, offsetDay = 0) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Set to start of the day
  newDate.setHours(newDate.getHours() - 8); // Subtract 8 hours for MYT timezone
  newDate.setDate(newDate.getDate() + offsetDay); // Add offset days
  return newDate || new Date();
};

const getNextStartOfDayMYT = (date: string | Date, offsetDay = 0) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Set to start of the day
  newDate.setHours(newDate.getHours() - 8); // Subtract 8 hours for MYT timezone
  newDate.setDate(newDate.getDate() + 1 + offsetDay); // Add 1 day for "next" and any offset days
  return newDate || new Date();
};

const utcToMytDate = (date: Date | null | undefined): Date => {
  if (!date) {
    return new Date();
  }

  return new Date(dayjs(date).utcOffset(8).format('YYYY-MM-DD'));
};

const utcToMytString = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }

  return dayjs(date).utcOffset(8).format('YYYY-MM-DD');
};

export {
  dayjs, 
  utc, isToday, isTomorrow, relativeTime, // important to not get compiling errors when using these plugins
  getStartOfDayMYT, getNextStartOfDayMYT,
  utcToMytDate, utcToMytString
};