import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ButtonIcon } from '@components/Button';
import Icon from '@components/Icon';
import Loader from '@components/Loader';
import { FAQActions, useFAQ } from '@contexts/faq';

const FAQ: React.FC = () => {
  const {
    dispatch,
    state: { url },
  } = useFAQ();

  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(true);

  return (
    <div className="fixed z-50 inset-0 flex justify-end backdrop-filter backdrop-blur-sm">
      <section className="bg-white overscroll-y-contain w-full md:max-w-xl md:border-l-2">
        <header className="flex justify-between w-full p-4">
          <h2 className="flex flex-1 justify-between gap-2">
            <div className="flex space-x-2">
              <Icon name="question-mark" size={1.5} />
              <span className="text-lg">{t('faq')}</span>
            </div>

            <div>
              <ButtonIcon
                text={t('close')}
                icon="close"
                onClick={() => dispatch({ type: FAQActions.Close })}
              />
            </div>
          </h2>
        </header>

        {loading && <Loader />}

        <iframe
          onLoad={() => {
            setLoading(false);
          }}
          src={url}
          className="w-full h-screen relative -top-38"
          title="Oyen FAQ"
        />
      </section>
    </div>
  );
};

export default FAQ;
