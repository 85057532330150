import * as React from "react"
const ClaimsHistoryIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#374151"
      d="M9.001.667A8.333 8.333 0 1 1 .668 9h1.667a6.667 6.667 0 1 0 1.154-3.75h2.179v1.667h-5v-5h1.667V4A8.317 8.317 0 0 1 9 .667Zm.834 4.167v3.82l2.702 2.703-1.179 1.18-3.19-3.193v-4.51h1.667Z"
    />
  </svg>
)
export default ClaimsHistoryIcon
