import React from "react"
import Button from "./Button";
import Icon from "./Icon";
class ErrorBoundary extends React.Component<{ children: React.ReactNode | React.ReactNode[]}, {hasError: boolean}> {

  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.error({ error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
          <a href="https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C+I+have+encountered+an+error%2C+please+help&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
            <Button outlined>
              <Icon name="headphones" baseSize={22} viewBox="0 0 22 22" />
              Reach out to us
            </Button>
          </a>
        </div>
      )
    }
    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary