import * as React from "react"
const ReferralIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#374151"
      d="M1.22 6.161 9.614 1.13a.908.908 0 0 1 .934 0l8.384 5.033a.455.455 0 0 1 .221.39v11.54a.908.908 0 0 1-.908.908H1.908A.908.908 0 0 1 1 18.092V6.55a.454.454 0 0 1 .22-.389Zm1.595 1.16v9.864h14.522V7.32L10.08 2.965 2.815 7.32Zm7.316 5.052 4.806-4.05 1.17 1.388-5.964 5.025-6.09-5.019 1.155-1.401 4.922 4.057Z"
    />
  </svg>
)
export default ReferralIcon
