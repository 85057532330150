import { CSSProperties } from "react";

type Props = {
  loading?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  fullscreen?: boolean;
  hideMobile?: boolean;
  className?: string;
  customStyling?: CSSProperties;
}

function Loader({ loading = true, children, fullscreen = false, className, hideMobile = false, customStyling }: Props) {
  return (
    <div className={`${loading ? 'Loader': ''} mx-auto min-h-[40px] p-0 mx-auto ${hideMobile ? 'hidden': ''} md:block relative ${fullscreen? ' w-full': '' } ${className}`}>
      {children}
      {loading && <>
      <div className={`bg-white opacity-80 w-full h-full absolute left-0 top-0 z-200`}>
      </div>
      <div className="absolute top-0 left-0 w-full h-full text-center" >
        {/*  50% top for visible box, fullscreen: 50% viewport, assume no horizontal scrolling */}
        <div className={`lds-ring ${fullscreen? 'fixed top-[calc(50vh-40px)] ': 'top-[calc(50%-40px)]'} `} style={customStyling} >
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      </>}
    </div>
  );
}

export default Loader;
