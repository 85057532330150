import * as React from "react"
const ListIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
    className="ant-menu-item-icon"
  >
    <g clipPath="url(#a)">
      <path
        fill="#494C55"
        d="M16.667 18.334H3.333A.833.833 0 0 1 2.5 17.5v-15a.833.833 0 0 1 .833-.833h13.334a.833.833 0 0 1 .833.833v15a.833.833 0 0 1-.833.834Zm-.834-1.667V3.334H4.167v13.333h11.666ZM6.667 5.834h6.666V7.5H6.667V5.834Zm0 3.333h6.666v1.667H6.667V9.167Zm0 3.333h6.666v1.667H6.667V12.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default ListIcon
