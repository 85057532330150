import { StateCreator } from 'zustand';
import { Pricing } from '@slices/types';
import { Store } from '@stores/store';

interface PricingSlice {
  pricing: Record<string, Pricing> | null;
  updatePricing: (name: string, pricing: Pricing) => void;
  setPricings: (pricings: Record<string, Pricing>) => void;
}

const createPricingSlice: StateCreator<Store, [], [], PricingSlice> = (set) => {
  return {
    pricing: null,
    updatePricing: (name: string, pricing: Pricing) =>
      set((state) => ({ pricing: { ...state.pricing, [name]: pricing } })),
    setPricings: (pricings: Record<string, Pricing>) =>
      set({ pricing: pricings }),
  };
};

export { type PricingSlice, createPricingSlice };
