import { StateCreator } from 'zustand';
import { Coverage } from '@slices/types';
import { Store } from '@stores/store';

interface CoverageSlice {
  coverage: Record<string, Coverage> | null;
  updateCoverage: (name: string, pricing: Coverage) => void;
  setCoverages: (coverages: Record<string, Coverage>) => void;
}

const createCoverageSlice: StateCreator<Store, [], [], CoverageSlice> = (
  set
) => {
  return {
    coverage: null,
    setCoverages: (coverages: Record<string, Coverage>) =>
      set({ coverage: coverages }),
    updateCoverage: (name: string, coverage: Coverage) =>
      set((state) => ({
        coverage: {
          ...state.coverage,
          [name]: coverage,
        },
      })),
  };
};

export { type CoverageSlice, createCoverageSlice };
