/* eslint-disable @typescript-eslint/ban-ts-comment */
// const env_check = !process.env.NEXT_ISDEV
//   && !process.env.NEXT_PUBLIC_APP_URL?.includes('localhost');

import { PaymentSource } from '@components/Payments/DropIn';

const env_check =
  !process.env.NEXT_PUBLIC_APP_URL?.includes('localhost') &&
  !process.env.NEXT_PUBLIC_APP_URL?.includes('oyen.local');

const dataLayerPush = (type: string, event: string, properties: any = {}) => {
  try {
    if (
      typeof window !== undefined &&
      'dataLayer' in window &&
      // @ts-ignore
      window.dataLayer &&
      // @ts-ignore
      window.dataLayer.push
    ) {
      // @ts-ignore
      window.dataLayer?.push({
        type,
        event,
        properties,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

const actions = {
  identify: (event: string, properties: any = {}) => {
    if (env_check) {
      dataLayerPush('identify', event, properties);
    }
  },

  track: (event: string, properties: any = {}) => {
    if (env_check) {
      dataLayerPush('track', event, properties);
    }
  },

  ecommerce: (event: string, properties: any = {}) => {
    if (env_check) {
      dataLayerPush('ecommerce', event, properties);
    }
  },
};
export const gtm = actions;

export const gtmTrack = (status: string, source: PaymentSource) => {
  const layerName = status + '_' + source;

  gtm.track(layerName, {
    event: layerName,
    eventCategory: 'plan_payment',
    eventAction: 'click',
    eventLabel: 'plan_payment',
  });
};
