import { PetType } from '@prisma/client';
import { Leads } from '@schemas/leads';
import { ELIGIBILITY_CODE } from '@utils/constant';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export enum ActionType {
  Previous,
  Next,
  Submit,
  Results,
  Restart,
  Save,
  Reset,
}
type Action = { type: ActionType; payload?: any; save?: boolean };
export type Dispatch = (action: Action) => void;
export enum Steps {
  PetInfo,
  PetGender,
  PetBreed,
  PetSize,
  PetDOB,
  PetResidence,
  PetAbility,
  PetHistory,
  PetMicrochip,
  PetVaccine,
  OwnerInfo,
  Results,
}
type Eligibility = {
  eligible: boolean;
  eligibilityCode: Array<typeof ELIGIBILITY_CODE>;
};

type State = {
  step: Steps;
  data: Leads;
  eligibility: Eligibility;
};
type EligibilityProviderProps = { children: React.ReactNode };
type ContextProps = {
  state: State;
  dispatch: Dispatch;
};

const EligibilityContext = React.createContext<ContextProps | undefined>(
  undefined
);

const eligibilityReducer = (state: State, action: Action) => {
  const isCat = state.data.petType === PetType.CAT;
  const isEligible = action.payload?.eligible;
  switch (action.type) {
    case ActionType.Reset:
      if (typeof window !== 'undefined') {
        window.localStorage.eligibility = undefined;
        window.localStorage.eligibilityAt = undefined;
      }
      return { data: {}, step: Steps.PetInfo, eligibility: {} as Eligibility };

    case ActionType.Save:
      if (typeof window !== 'undefined') {
        window.localStorage.eligibility = JSON.stringify(state);
        window.localStorage.eligibilityAt = new Date();
      }
      return {
        data: { ...state.data, ...action.payload },
        step: Steps.PetBreed,
        eligibility: state.eligibility,
      };
    case ActionType.Previous:
      // Skip PetSize for Cats
      if (state.step === Steps.PetDOB && isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetBreed,
          eligibility: state.eligibility,
        };
      }

      // Skip to PetAbility for Cats
      if (state.step === Steps.OwnerInfo && isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetAbility,
          eligibility: state.eligibility,
        };
      }

      // Skip PetVaccine for Dogs
      if (state.step === Steps.OwnerInfo && !isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetMicrochip,
          eligibility: state.eligibility,
        };
      }

      // Skip PetHistory for Dogs
      if (state.step === Steps.PetMicrochip && !isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetAbility,
          eligibility: state.eligibility,
        };
      }
      return {
        step: state.step - 1,
        data: state.data,
        eligibility: state.eligibility,
      };

    case ActionType.Next:
      // Skip PetSize for Cats
      if (state.step === Steps.PetBreed && isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetDOB,
          eligibility: state.eligibility,
        };
      }
      // Skip to OwnerInfo for Cats
      if (state.step === Steps.PetAbility && isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.OwnerInfo,
          eligibility: state.eligibility,
        };
      }

      // Skip PetHistory for Dogs
      if (state.step === Steps.PetAbility && !isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.PetMicrochip,
          eligibility: state.eligibility,
        };
      }

      // Skip PetVaccine for Dogs
      if (state.step === Steps.PetMicrochip && !isCat) {
        return {
          data: { ...state.data, ...action.payload },
          step: Steps.OwnerInfo,
          eligibility: state.eligibility,
        };
      }
      return {
        data: { ...state.data, ...action.payload },
        step: state.step + 1,
        eligibility: state.eligibility,
      };
    case ActionType.Submit:
      return {
        data: { ...state.data, ...action.payload },
        step: state.step,
        eligibility: state.eligibility,
      };
    case ActionType.Results:
      // eslint-disable-next-line no-case-declarations
      const updateState = {
        data: state.data,
        eligibility: action.payload,
        step: Steps.Results,
      };
      if (isEligible || action.save) {
        if (isEligible || action.save) {
          window.localStorage.eligibility = JSON.stringify(updateState);
          window.localStorage.eligibilityAt = new Date();
        }

        return updateState;
      }
      return updateState;
    case ActionType.Restart:
      window.localStorage.clear();
      return { data: {}, step: Steps.PetInfo, eligibility: {} as Eligibility };
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

const EligibilityProvider = ({ children }: EligibilityProviderProps) => {
  const [involveAsia, setIACookie] = useCookies(['involve_asia']);

  useEffect(() => {
    const queryString = window.location.search;
    if (
      typeof window !== 'undefined' &&
      !involveAsia.involve_asia &&
      queryString.length > 0
    ) {
      const urlParams = new URLSearchParams(queryString);
      const utmSource = urlParams.get('utm_source');
      const utmMedium = urlParams.get('utm_medium');
      const utmClickId = urlParams.get('click_id');
      if (queryString.length > 0) {
        setIACookie(
          'involve_asia',
          JSON.stringify({
            utm_source: utmSource,
            utm_medium: utmMedium,
            clickId: utmClickId,
          }),
          {
            path: '/',
            maxAge: 60 * 60 - 60 * 5,
            sameSite: false,
          }
        );
      }
    }
  }, []);

  const keepDuration = 1000 * 60 * 60 * 24;
  const hasData =
    typeof window !== 'undefined' && window.localStorage.eligibilityAt;
  const expiredAt: Date | undefined =
    hasData && window.localStorage.eligibilityAt
      ? new Date(window.localStorage.eligibilityAt)
      : new Date();
  const notExpired =
    hasData && expiredAt && expiredAt?.getTime() + keepDuration >= Date.now();
  // const notExpired = true;
  const initialState =
    hasData && notExpired
      ? JSON.parse(window.localStorage.eligibility)
      : {
          step: Steps.PetInfo,
          data: {} as Leads,
          eligibility: {} as Eligibility,
        };
  if (!hasData && typeof window !== 'undefined') {
    window.localStorage.eligibilityAt = undefined;
    window.localStorage.eligibility = undefined;
  }
  const [state, dispatch] = React.useReducer(eligibilityReducer, initialState);

  const value = { state, dispatch };
  return (
    <EligibilityContext.Provider value={value}>
      {children}
    </EligibilityContext.Provider>
  );
};

const useEligibility = () => {
  const context = React.useContext(EligibilityContext);
  if (context === undefined) {
    throw new Error('useEligibility must be used inside EligibilityProvider');
  }
  return context;
};

export { EligibilityProvider, useEligibility };
