import * as React from "react"
const BenefitsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#494C55"
      d="m11.999 18.26-7.053 3.948L6.52 14.28.586 8.792l8.027-.952L11.999.5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928-7.053-3.948Zm0-2.292 4.247 2.377-.95-4.773 3.574-3.305-4.833-.573-2.038-4.419-2.038 4.42-4.833.572L8.7 13.572l-.95 4.773L12 15.968Z"
    />
  </svg>
)
export default BenefitsIcon
