import { Store } from '@stores/store';
import { plansFormSchema } from 'pages/eligibility/v3/plans';
import * as z from 'zod';
import { StateCreator } from 'zustand';

interface PlanSlice {
  plan: Record<string, Partial<z.infer<typeof plansFormSchema>>>;
  updatePlan: (name: string, plan: z.infer<typeof plansFormSchema>) => void;
  setPlans: (plans: Record<string, z.infer<typeof plansFormSchema>>) => void;
}

const createPlanSlice: StateCreator<Store, [], [], PlanSlice> = (set) => {
  return {
    plan: {},
    updatePlan: (name: string, plan: z.infer<typeof plansFormSchema>) =>
      set((state) => ({ plan: { ...state.plan, [name]: plan } })),
    setPlans: (plans: Record<string, z.infer<typeof plansFormSchema>>) =>
      set({ plan: plans }),
  };
};

export { type PlanSlice, createPlanSlice };
