import { httpBatchLink, inferRouterProxyClient } from "@trpc/client";
import { useEffect, useState } from "react";
import { useAuth } from "../lib/auth";
import { useRef} from "react";
import { AppRouter } from "../server/routers/_app";
import { createTRPCProxyClient } from '@trpc/client';
import superjson from 'superjson';

export const useEffectAuth = (callback : () => any, deps = [] as any[]) => {
  const { auth } = useAuth();

  useEffect(() => {
    if (auth?.isAuthenticated) {
      // console.log("auth", auth);
      callback();
    }
  }, [auth?.isAuthenticated, ...deps]);

  return { auth };
}

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';
  if (process.env.NEXT_PUBLIC_APP_URL)
    // reference for vercel.com
    return `https://${process.env.NEXT_PUBLIC_APP_URL}`;
  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const useTRPCWithAuth = (callback? : (client: inferRouterProxyClient<AppRouter>) => any, deps = [] as any[]) => {
  const { auth, authToken } = useAuth();
  const [trpcReady, setTrpcReady] = useState(false);
  const createClient = () => createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      httpBatchLink({
        /**
        * If you want to use SSR, you need to use the server's full URL
        * @link https://trpc.io/docs/ssr
        **/
        url: `${getBaseUrl()}/api/trpc`,
        async headers() {
          // const token = auth?.isAuthenticated;
          // console.log('auth?.isAuthenticated', auth?.isAuthenticated);
          const token = await authToken();
          // console.log('usetrpc token?', token);
          if (token) {
            return {
              Authorization: `Bearer ${token}`,
            }
          }
          return {
          };
        },
      }),
    ],
  })
  const client = useRef<inferRouterProxyClient<AppRouter>>(createClient());
  useEffect(() => {
    if (!trpcReady && auth?.isAuthenticated) {
      // console.log("auth token changed", auth);
      client.current = createClient();
      setTrpcReady(true);
    } 
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    // console.log("client.current", client.current);
    if (auth?.isAuthenticated && !!callback) {
      callback(client.current);
    }
  }, [client.current, trpcReady, ...deps]);

  return { auth, trpcReady, client: client };
};