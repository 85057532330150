import { Store } from '@stores/store';
import { addressFormSchema } from 'pages/eligibility/v3/address';
import { StateCreator } from 'zustand';
import * as z from 'zod';

interface AddressSlice {
  address: Partial<z.infer<typeof addressFormSchema>>;
  updateAddress: (address: z.infer<typeof addressFormSchema>) => void;
}

const createAddressSlice: StateCreator<Store, [], [], AddressSlice> = (set) => {
  return {
    address: {},
    updateAddress: (address: z.infer<typeof addressFormSchema>) =>
      set({ address }),
  };
};

export { type AddressSlice, createAddressSlice };
