import { UserPetPolicies } from '@prisma/client';
import { Store } from '@stores/store';
import { StateCreator } from 'zustand';

interface UserPetPolicySlice {
  upp: UserPetPolicies[];
  setUserPetPolicies: (userPetPolicies: UserPetPolicies[]) => void;
}

const createUserPetPolicySlice: StateCreator<
  Store,
  [],
  [],
  UserPetPolicySlice
> = (set) => {
  return {
    upp: [],
    setUserPetPolicies: (userPetPolicies: UserPetPolicies[]) =>
      set({ upp: userPetPolicies }),
  };
};

export { type UserPetPolicySlice, createUserPetPolicySlice };
