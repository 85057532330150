import { inferRouterProxyClient } from '@trpc/client';
import { Context, useContext } from 'react';
import { AppRouter } from '../server/routers/_app';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTRPCWithAuth } from '../hooks/useEffectAuth';

export let trpcContext: Context<{
  client: inferRouterProxyClient<AppRouter>;
  trpcReady: boolean;
}>;

export function TRPCContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { trpcReady, client } = useTRPCWithAuth();
  // console.log('client?' , client);
  const [queryClient] = React.useState(() => new QueryClient());

  trpcContext = React.createContext({ client: client.current, trpcReady });

  return (
    <trpcContext.Provider value={{ client: client.current, trpcReady }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpcContext.Provider>
  );
}

export const useTRPCContext = () => useContext(trpcContext);
