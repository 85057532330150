import React from 'react';
import { Layout } from 'antd';
import dayjs from 'dayjs';

import { useAuth } from '@lib/auth';
import Sidebar from './Sidebar';

const { Content } = Layout;

type LayoutProps = {
  children: React.ReactNode;
};

function DashboardLayout({ children }: LayoutProps) {
  const { auth } = useAuth();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="min-h-screen md:flex relative bg-white w-full">
        {auth && <Sidebar />}
        <div className="flex flex-col w-full md:border-l">
          {children}
          <div className="flex flex-col text-center gap-4 text-gray-400 mb-10 mx-4">
            <div>
              Copyright @ {dayjs().year()} Oyen Sdn Bhd | All Rights Reserved.
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default DashboardLayout;
