import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '@public/logo.svg';
import { LANGUAGES, OYEN_LANDING_PAGE } from '@utils/constant';
import SelectLanguage from '@components/SelectLanguage';

type AuthLayoutProps = {
  children: React.ReactNode;
};

function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <main className="Layout">
      <header className="AuthLayoutHeader">
        <Link href={OYEN_LANDING_PAGE} passHref>
          <a href="passRef" className="flex">
            <Image src={Logo} height={29} width={100} />
          </a>
        </Link>

        <SelectLanguage languages={LANGUAGES} />
      </header>
      {children}
      <footer className="LayoutFooter">
        <p>
          <small>
            &copy;
            {new Date().getFullYear()}
          </small>
        </p>
      </footer>
    </main>
  );
}

export default AuthLayout;
